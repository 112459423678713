import React from 'react';
import { BannerService, ServiceDigital } from '../../sections/Service';

const ServicePage = () => {
  return (
    <div>
      <BannerService />
      <ServiceDigital />
    </div>
  );
};

export default ServicePage;
