import React from 'react';

const DockerContent = () => {
  return (
    <div className="container">
      <h3 className="py-3">
        Spring Boot Application as Docker Container Image on AWS EC2
      </h3>
      <p>
        When we dive in to microservice architecture, we search for many ways to
        deploy it. Docker tool helps us to deploy multiple microservices /
        applications as containers. Let’s deep dive in to setting up docker
        service in AWS EC2 and deploy simple or complex spring boot
        applications. Assuming that you already have a java application written
        in spring boot framework, let’s see how to build it as a docker image
        and deploy as a microservice. We can choose either jar or war packaging
        to deploy in to docker service, which really doesn’t make any big
        difference.
      </p>
      <h6>
        <b>Building a Spring boot application for docker container</b>
      </h6>
      <p>
        Let it be simple straight forward application or a complex algorithm
        one, just follow the below steps,
      </p>
      <p>
        1.Create a new file in any IDE with type as File and name it as Docker
      </p>
      <img src="/images/dockercontent.jpg" className="img-fluid" />
      <p>
        2.Docker container uses its own environment; hence we have to configure
        JDK for all images that we build. Here we are using JDK 17 slim, you can
        search for your specific jdk version on the web.
      </p>
      <p>
        3.Just paste the below contents in the Docker file (change application
        name with your project name)
      </p>
      <pre>
        {`FROM openjdk:17-jdk-slim`}
        <br />
        {`ARG JAR_FILE=./amizhthblog.jar`}
        <br />
        {`COPY JAR_FILE amizhthblog.jar`}
        <br />
        {`ENTRYPOINT ["java","-jar","amizhthblog.jar"]`}
      </pre>
      <p>
        Line 1: JDK to be used <br /> Line 2: The path where the jar is located.{' '}
        <br />
        Line 3: Copying the jar file from path in to the container <br /> Line
        4: Telling docker what to look for.
      </p>
      <p>
        4. Build your spring boot application using maven. The docker file is
        not necessarily to be present inside the application package.
      </p>
      <p>
        <b>Installing Docker Service in AWS EC2</b>
      </p>
      <p>
        <b>1.Install Docker</b>
      </p>
      <p>sudo yum install docker</p>
      <p>
        <b>2.Give ec2-user full access over docker service</b>
      </p>
      <p>sudo usermod -a -G docker ec2-user id ec2-user newgrp docker</p>
      <p>
        <b>
          3. Enabling docker service with systemctl which will start the docker
          service if the server reboot happens
        </b>
      </p>
      <p>sudo systemctl enable docker.service</p>
      <p>
        <b>4. Start docker service</b>
      </p>
      <p>sudo systemctl start docker.service</p>
      <p>
        <b>5. Check status</b>
      </p>
      <p>sudo systemctl status docker.service</p>
      <p>
        <b>Deploy spring boot container image</b>
      </p>
      <p>
        1.place your application jar / war file and Docker file under
        /home/ec2-user/docker (the location can be anything that you wish) 2.run
        the command docker build -t amizhthblog . 3.bind the container to
        respective port and run the service, here we are binding to port 80
        docker run -d -e SERVER_PORT=80 -p 80:80 amizhthblog. 4.To check the
        logs, follow the steps below a. Run the command docker ps -a b. copy the
        container id which will be like 93053c4902b c. Run the command docker
        logs -f 93053c4902b0
      </p>
    </div>
  );
};

export default DockerContent;
