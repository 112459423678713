import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const Banner = () => {
  useEffect(() => {
    // Preload the most critical images (e.g., hero image) for LCP
    const preloadImages = [
      '/images/main-img.avif',
      '/images/banner-1.webp',
      '/images/roket.avif',
      '/images/icon.avif',
      '/images/shape-img-1.avif',
      '/images/line.avif',
      '/images/mountain-2.webp'
    ];

    preloadImages.forEach((image) => {
      const link = document.createElement('link');
      link.rel = 'preload';
      link.href = image;
      link.as = 'image';
      document.head.appendChild(link);
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>
          Amizhth: Leading IT Consulting & Software Development company in India{' '}
        </title>
      </Helmet>
      <link
        rel="preload"
        href="/images/main-img.avif"
        as="image"
        type="image/avif"
      />
      <link
        rel="preload"
        href="/images/banner-1.webp"
        as="image"
        type="image/webp"
      />
      <link
        rel="preload"
        href="/images/roket.avif"
        as="image"
        type="image/avif"
      />
      <link
        rel="preload"
        href="/images/icon.avif"
        as="image"
        type="image/avif"
      />
      <link
        rel="preload"
        href="/images/shape-img-1.avif"
        as="image"
        type="image/avif"
      />
      <link
        rel="preload"
        href="/images/line.avif"
        as="image"
        type="image/avif"
      />
      <link
        rel="preload"
        href="/images/mountain-2.webp"
        as="image"
        type="image/webp"
      />

      {/* Load Swiper JS after the page load */}
      <script
        src="https://cdn.jsdelivr.net/npm/swiper@9/swiper-bundle.min.js"
        defer
      ></script>

      <div className="slider_area d-flex align-items-center slider18" id="home">
        <div className="container">
          <div className="row d-flex align-items-center">
            {/* Left Content */}
            <div className="col-md-6 col-lg-6">
              <div className="single_slider wow fadeInUp">
                <div className="slider_content">
                  <div className="slider_text">
                    <div className="slider_text_inner">
                      <h5></h5>
                      <h2 className="pt-40">
                        Let’s Build Your Digital Footprints!
                      </h2>

                      <p>Secure, Scale, Succeed</p>
                    </div>
                    <div className="slider_button pt-3 d-flex">
                      <div className="button">
                        <Link to="/contact">
                          Let&apos;s Talk <i className="bi bi-arrow-right"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="em-hero-inner-shape rotateme">
                  <img src="/images/box.avif" alt="Box Shape" loading="eager" />
                </div>
                <div className="em-hero-inner-shape1">
                  <img
                    src="/images/line.avif"
                    alt=""
                    loading="eager"
                    srcSet="/images/line.avif"
                  />
                </div>
              </div>
            </div>

            {/* Right Content */}
            <div className="col-md-6 col-lg-6">
              <div className="hero_section_thumb wow fadeInDown">
                <div className="hero-main-thumb">
                  <img
                    src="/images/main-img.avif"
                    alt=""
                    loading="eager"
                    srcSet="/images/main-img.avif 1x, /images/main-img@2x.avif 2x"
                    sizes="(max-width: 768px) 100vw, 50vw"
                  />
                </div>
                <div className="em-hero-icon-thumb bounce-animate4">
                  <img
                    src="/images/shape-img-1.avif"
                    alt=""
                    loading="eager"
                    srcSet="/images/shape-img-1.avif"
                  />
                </div>
                <div className="em-hero-inner-thumb2 bounce-animate">
                  <img
                    src="/images/roket.avif"
                    alt=""
                    loading="eager"
                    srcSet="/images/roket.avif"
                  />
                </div>
                <div className="em-hero-inner-thumb4 bounce-animate5">
                  <img
                    src="/images/icon.avif"
                    alt=""
                    loading="eager"
                    srcSet="/images/icon.avif"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Decorative Image - Lazy load background */}
      <img
        src="/images/mountain-2.webp"
        alt=""
        loading="lazy"
        srcSet="/images/mountain-2.webp"
        className="mountain-img img-fluid"
      />
    </>
  );
};

export default Banner;
