// import { Button, TextField } from '@mui/material';
import React from 'react';
// import { Controller, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

const BlogList = () => {
  // const {
  //   control,
  //   handleSubmit,
  //   formState: { errors }
  // } = useForm();

  // const onSubmit = (data) => {
  //   console.log(data);
  // };

  return (
    <>
      <div className="blog_area pt-85 pb-65">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="single_blog mb-30">
                <div className="single_blog_thumb pb-4">
                  <Link to="/blog/cloud-service">
                    <img
                      src="/images/blog-pic1.webp"
                      alt="Learn about Cloud Services"
                    />
                  </Link>
                </div>
                <div className="single_blog_content pl-4 pr-4">
                  <div className="techno_blog_meta">
                    <Link to="/blog/cloud-service">Amizhth</Link>
                    <span className="meta-date pl-3">
                      March 24, 2024 | By Pradeepa
                    </span>
                  </div>
                  <div className="blog_page_title pb-25">
                    <p>
                      <Link to="/blog/cloud-service">
                        Modernise Applications by Exploiting the Cloud <br />{' '}
                        Services.
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="single_blog mb-30">
                <div className="single_blog_thumb pb-4">
                  <Link to="/blog/springboot-tomcat">
                    <img
                      src="/images/blog-pic2.webp"
                      alt="Spring Boot with Tomcat"
                    />
                  </Link>
                </div>
                <div className="single_blog_content pl-4 pr-4">
                  <div className="techno_blog_meta">
                    <Link to="/blog/springboot-tomcat">Amizhth</Link>
                    <span className="meta-date pl-3">
                      June 3, 2024 | By Antony Basco
                    </span>
                  </div>
                  <div className="blog_page_title pb-25">
                    <p>
                      <Link to="/blog/springboot-tomcat">
                        Spring Boot Application on AWS EC2 using Apache Tomcat
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="single_blog mb-30">
                <div className="single_blog_thumb pb-4">
                  <Link to="/blog/springboot-docker">
                    <img
                      src="/images/blog-pic3.webp"
                      alt="Spring Boot with Docker"
                    />
                  </Link>
                </div>
                <div className="single_blog_content pl-4 pr-4">
                  <div className="techno_blog_meta">
                    <Link to="/blog/springboot-docker">Amizhth</Link>
                    <span className="meta-date pl-3">
                      August 4, 2024 | By Antony Basco{' '}
                    </span>
                  </div>
                  <div className="blog_page_title">
                    <p>
                      <Link to="/blog/springboot-docker">
                        Spring Boot Application as Docker Container Image on AWS
                        EC2
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogList;
