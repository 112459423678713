import React from 'react';
import BlogBanner from '../../sections/BlogPage/Banner';
import BlogList from '../../sections/BlogPage/BlogList/BlogList';

const BlogPage = () => {
  return (
    <>
      <BlogBanner />
      <BlogList />
    </>
  );
};

export default BlogPage;
