import React from 'react';

const TomcatBanner = () => {
  return (
    <>
      <div className="breatcome_area6 d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="breatcome_title"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TomcatBanner;
