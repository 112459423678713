import React from 'react';
import Dockerbanner from '../../sections/BlogDocker/Dockerbanner';
import DockerContent from '../../sections/BlogDocker/DockerContent';

const BlogDocker = () => {
  return (
    <>
      <Dockerbanner />
      <DockerContent />
    </>
  );
};

export default BlogDocker;
