import React from 'react';
import ContactBanner from '../../sections/ContactPage/ContactBanner/ContactBanner';
// import ContactQuote from '../../sections/ContactPage/ContactQuote/ContactQuote';
import { Contactus } from '../../sections/ContactPage';

const ContactPage = () => {
  return (
    <>
      <ContactBanner />
      <Contactus />
      {/* <ContactQuote /> */}
    </>
  );
};

export default ContactPage;
