import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import DataContext from './context/DataContext';
import { Layout } from './components';
import {
  AboutPage,
  BlogCloudservice,
  BlogPage,
  BlogTomcat,
  ContactPage,
  HomePage,
  PrivacyPolicy,
  ServicePage,
  Termsandcondition
} from './pages';

import BlogDocker from './pages/BlogDocker/BlogDocker';

const Baseroute = () => {
  console.log(Router);

  const { pageLoading } = useContext(DataContext);
  return (
    <div>
      {pageLoading && (
        <div className="loader_div">
          <div className="ring"></div>
          <div className="ring"></div>
          <div className="ring"></div>
        </div>
      )}
      <main>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/" index element={<HomePage />} />
            <Route path="/about" index element={<AboutPage />} />
            <Route path="/service" index element={<ServicePage />} />
            <Route path="/blog" index element={<BlogPage />} />
            <Route
              path="/blog/cloud-service"
              index
              element={<BlogCloudservice />}
            />
            <Route
              path="/blog/springboot-tomcat"
              index
              element={<BlogTomcat />}
            />
            <Route
              path="/blog/springboot-docker"
              index
              element={<BlogDocker />}
            />
            <Route path="/contact" index element={<ContactPage />} />
            <Route path="/privacypolicy" index element={<PrivacyPolicy />} />
            <Route
              path="/termsandconditions"
              index
              element={<Termsandcondition />}
            />
          </Route>
        </Routes>
      </main>
    </div>
  );
};

export default Baseroute;
