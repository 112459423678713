import React from 'react';
import { Link } from 'react-router-dom';

const Service = () => {
  const features = [
    {
      title: 'App Development',
      description:
        'We are a well-recognized mobile app development company known for the timely delivery of future-ready mobile applications with exceptional quality. ',
      image: '/images/ser1.webp'
    },
    {
      title: 'Web Development',
      description:
        'As a leading web development service provider we are ready to develop customized websites in a way to boost your digital identity.',
      image: '/images/ser2.webp'
    },
    {
      title: 'IT Outsourcing',
      description:
        'We offer customized solutions designed to foster innovation, optimize your workflows, and ensure your IT environment is secure.',
      image: '/images/ser6.webp',
      Style: { Marginbottom: '2.4' }
    },
    {
      title: 'Cyber Security',
      description:
        'We offer premium Virtual CISO services, and robust risk management solutions designed to protect businesses of all sizes.',
      image: '/images/ser4.webp'
    },
    {
      title: 'Digital Marketing',
      description:
        'We help you to drive high traffic, retain customers, and generate leads for your business, thus strengthening your reputation.',
      image: '/images/ser8.webp'
    },
    {
      title: 'UI/UX Design',
      description:
        'We put our creative skills and technical advancement to create perfect UI/UX design to provide a hassle-free user experience.',
      image: '/images/ser3.webp'
    },
    {
      title: 'Cloud Services',
      description:
        'Our team of experts collaborates with you to understand your unique needs and craft a tailored solution that fits both your goals and budget.',
      image: '/images/ser5.webp'
    },
    {
      title: 'Data Analytics',
      description:
        'As a leading data analytics service, we empower businesses in various industries meet their challenging needs at enterprise levels.',
      image: '/images/ser7.webp'
    }
  ];

  return (
    <>
      <div
        className="tree-bg feature-area owl-nav3 style-two"
        // style={{
        //   backgroundImage: "url('/images/Tree.png')",
        //   backgroundSize: 'cover',
        //   backgroundPosition: 'center',
        //   backgroundRepeat: 'no-repeat',
        //   position: 'relative',
        //   top: '-200px'
        // }}
      >
        <div className="container">
          <div className="row feature-shape">
            <div className="col-lg-8">
              <div
                className="section_title style-two mb-30 pb-1 mt-3 wow fadeInDown animated"
                data-wow-delay=".4"
                style={{ visibility: 'visible', animationName: 'fadeInLeft' }}
              >
                <div className="section_sub_title">
                  <p>Services</p>
                </div>
                <div className="section_main_title pb-15">
                  <h2>
                    Building Digital Success - Explore Our Full Range of
                    Services
                  </h2>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="em-feature-button text-right">
                <Link to="/service">
                  All Services <i className="bi bi-arrow-right"></i>
                </Link>
              </div>
            </div>
            <div className="feature-shape1">
              <img src="images/border-f.webp" loading="lazy" alt="" />
            </div>
            <div className="feature-shape2 bounce-animate3">
              <img src="images/service-sp.webp" alt="" />
            </div>
          </div>

          <div className="container py-5">
            <div className="row">
              {features.map((feature, index) => (
                <div key={index} className="col-lg-3 col-md-6 mb-4">
                  <div className="feature-extra-single-box">
                    <div
                      className="em-feature-single-box wow fadeInLeft animated"
                      data-wow-delay=".5"
                      style={{
                        visibility: 'visible',
                        animationName: 'fadeInLeft'
                      }}
                    >
                      <div className="feature-single-box-inner">
                        <div className="feature-top-icon">
                          <img
                            src={feature.image}
                            className="img-fluid w-50"
                            alt={feature.title}
                            loading="lazy"
                          />
                        </div>
                        <div className="em-feature-title">
                          <h3>{feature.title}</h3>
                          <p>{feature.description}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Service;
