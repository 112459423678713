import React from 'react';
import TomcatBanner from '../../sections/BlogTomcat/TomcatBanner/TomcatBanner';
import TomcatContent from '../../sections/BlogTomcat/TomcatContent/TomcatContent';

const BlogTomcat = () => {
  return (
    <>
      <TomcatBanner />
      <TomcatContent />
    </>
  );
};

export default BlogTomcat;
