import React from 'react';
import {
  Banner,
  Contact,
  Partner,
  Process,
  Service,
  Techno,
  Blog
} from '../../sections';

const HomePage = () => {
  return (
    <div>
      <Banner />
      <Service />
      <Techno />
      <Process />
      <Partner />
      <Contact />
      <Blog />
    </div>
  );
};

export default HomePage;
