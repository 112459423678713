import React from 'react';

const Process = () => {
  return (
    <>
      <div className="process-area style-two pb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="section_title text_center style-two up mb-90 mt-4 wow fadeInRight"
                data-wow-delay=".4"
              >
                <div className="section_sub_title">
                  <span>
                    <p>Our Process</p>
                  </span>
                </div>
                <div className="section_main_title">
                  <h4>The Path to Success</h4>
                </div>
                <div className="section_content_text1">
                  <p>
                    Discover how our company leads the way in prioritizing your
                    needs to deliver exceptional enterprise mobile applications.
                    We shape the future of your business with a steadfast
                    commitment to excellence. driving efficiency and meeting
                    your unique objectives.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row process-bg wow fadeInLeft" data-wow-delay=".5">
            <div className="col-lg-3 col-md-6">
              <div className="process-single-box">
                <div className="process-contant1">
                  <div className="process-number">
                    <div className="process-number-inner">
                      <span>01</span>
                    </div>
                  </div>
                  <div className="process-title">
                    <h5>Discovery</h5>
                  </div>
                  <div className="process-text">
                    <p style={{ fontSize: '11px' }}>
                      We work hand-in-hand to fully understand your project
                      goals and requirements, ensuring complete clarity and
                      alignment throughout the project.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="process-single-box upper">
                <div className="process-contant1">
                  <div className="process-number">
                    <div className="process-number-inner">
                      <span>02</span>
                    </div>
                  </div>
                  <div className="process-title">
                    <h5>Blueprinting</h5>
                  </div>
                  <div className="process-text">
                    <p style={{ fontSize: '11px' }}>
                      We develop a roadmap with wireframes, user journeys, and
                      technical specifications with a focus on user-centric
                      design and functionality.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="process-single-box">
                <div className="process-contant1">
                  <div className="process-number">
                    <div className="process-number-inner">
                      <span>03</span>
                    </div>
                  </div>
                  <div className="process-title">
                    <h5>Design Sprint</h5>
                  </div>
                  <div className="process-text">
                    <p style={{ fontSize: '11px' }}>
                      We utilize intuitive, user-focused designs to enhance your
                      apps functionality and visual appeal, creating a more
                      engaging user experience.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="process-single-box upper">
                <div className="process-contant1">
                  <div className="process-number">
                    <div className="process-number-inner">
                      <span>04</span>
                    </div>
                  </div>
                  <div className="process-title">
                    <h5>Code Crafting</h5>
                  </div>
                  <div className="process-text">
                    <p style={{ fontSize: '11.5px' }}>
                      As developers, we build resilient enterprise mobile apps
                      designed to adapt to the evolving demands of the
                      workplace.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="process-shape">
              <img src="/images/process-border.webp" loading="lazy" alt="" />
            </div>
            {/* <div className="process-shape-thumb">
              <img src="/images/process-circle.png" alt="" />
            </div> */}
          </div>

          <div
            className="row process-bg wow fadeInLeft pt-100"
            data-wow-delay=".5"
          >
            <div className="col-lg-3 col-md-6">
              <div className="process-single-box">
                <div className="process-contant1">
                  <div className="process-number">
                    <div className="process-number-inner">
                      <span>05</span>
                    </div>
                  </div>
                  <div className="process-title">
                    <h5>Growth</h5>
                  </div>
                  <div className="process-text">
                    <p style={{ fontSize: '11.5px' }}>
                      We scale and evolve with regular updates and continue to
                      adapt to user needs and industry changes for sustained
                      success.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="process-single-box upper">
                <div className="process-contant1">
                  <div className="process-number">
                    <div className="process-number-inner">
                      <span>06</span>
                    </div>
                  </div>
                  <div className="process-title">
                    <h5>Aftercare</h5>
                  </div>
                  <div className="process-text">
                    <p style={{ fontSize: '11px' }}>
                      Our continuous support ensures your product runs smoothly
                      and stays current, providing you with long-term peace of
                      mind.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="process-single-box">
                <div className="process-contant1">
                  <div className="process-number">
                    <div className="process-number-inner">
                      <span>07</span>
                    </div>
                  </div>
                  <div className="process-title">
                    <h5>Go Live</h5>
                  </div>
                  <div className="process-text">
                    <p style={{ fontSize: '11.5px' }}>
                      We launch the product on servers and app stores with zero
                      downtime by ensuring optimal performance from day one.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="process-single-box upper">
                <div className="process-contant1">
                  <div className="process-number">
                    <div className="process-number-inner">
                      <span>08</span>
                    </div>
                  </div>
                  <div className="process-title">
                    <h5>Launchpad Testing</h5>
                  </div>
                  <div className="process-text">
                    <p style={{ fontSize: '11px' }}>
                      We conduct rigorous testing for bugs, performance, and
                      cross-platform compatibility and gather user feedback for
                      a smooth, flawless launch.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="process-shape pt-100">
              <img src="/images/process-border.webp" loading="lazy" alt="" />
            </div>
            <div className="process-shape-thumb">
              <img src="/images/process-circle.webp" loading="lazy" alt="" />
            </div>
          </div>
        </div>
      </div>
      <img
        src="/images/sea.webp"
        alt=""
        loading="lazy"
        className="img-fluid mb-5"
      />
      <div className="subscribe-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="call-do-action-content">
                <div
                  className="call-do-action-title text_center wow fadeInDown"
                  data-wow-delay=".4"
                >
                  {/* <h1>Advance Feature to Give you Full Control</h1>
                  <p>
                    Completely strategize client-centric niche markets for go
                    forward plan sucess
                  </p> */}
                </div>
                <div
                  className="call-do-thumb-box wow fadeInRight"
                  data-wow-delay=".7s"
                >
                  <div className="call-do-thumb bounce-animate3">
                    <img src="/images/ring.png" alt="" />
                  </div>
                </div>
                <div
                  className="call-do-thumb-box wow fadeInLeft"
                  data-wow-delay=".6s"
                >
                  <div className="call-do-thumb1 bounce-animate2">
                    <img src="/images/man.png" loading="lazy" alt="" />
                  </div>
                </div>
                <div
                  className="call-do-thumb-box wow fadeInRight"
                  data-wow-delay=".8s"
                >
                  <div className="call-do-thumb2 bounce-animate5">
                    <img src="/images/ball.png" loading="lazy" alt="" />
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-12">
              <div className="contact_from">
                <form id="contact_form" action="mail.php" method="POST">
                  <div className="row">
                    <div className="col-lg-1"></div>
                    <div className="col-lg-4">
                      <div className="form_box mb-30">
                        <input type="text" name="name" placeholder="Your Name" />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form_box mb-30">
                        <input
                          type="text"
                          name="name"
                          placeholder="Your E-mail"
                        />
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="quote_btn">
                        <button className="btn" type="submit">Submit Now</button>
                      </div>
                    </div>
                  </div>
                </form>
                <p className="form-message"></p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Process;
