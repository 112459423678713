import React from 'react';
import { Helmet } from 'react-helmet';
const BannerService = () => {
  return (
    <>
      <Helmet>
        <title>Services | Amizhth Techno Solutions </title>
      </Helmet>
      <div className="breatcome_area1 d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="breatcome_title"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BannerService;
