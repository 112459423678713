import React from 'react';
import CloudserviceBanner from '../../sections/BlogCloudservice/CloudserviceBanner';
import Cloudservice from '../../sections/BlogCloudservice/Cloudservice';

const BlogCloudservice = () => {
  return (
    <>
      <CloudserviceBanner />
      <Cloudservice />
    </>
  );
};

export default BlogCloudservice;
