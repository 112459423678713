import React from 'react';

const TomcatContent = () => {
  return (
    <>
      <div className="container">
        <h3 className="py-3">
          Spring Boot Application on AWS EC2 using Apache Tomcat
        </h3>
        <p>
          Presently, everyone started to deploy their application on cloud
          services, developers started to explore better ways to deploy it. Most
          of us started to use AWS not just because it provides free service for
          a year but also the feasibility and availability does help us to
          achieve our goal. In this article we will deploy a spring boot
          application on tomcat service using AWS EC2.
        </p>

        <h3>Without further ado, let’s see the steps involved in it.</h3>
        <ol className="pl-3 mt-3">
          <li>Build Spring Boot application WAR</li>
          <li>Set up an EC2 instance on AWS</li>
          <li>Install JDK and Apache Tomcat</li>
          <li>Deploy your application WAR on EC2</li>
        </ol>

        <center>
          <h3>Let’s see in detail</h3>
        </center>

        <h6>
          <b>1.Build Spring boot application war:</b>
        </h6>
        <p>
          This is the easiest step for all developers, you must be already aware
          of how to generate a war using IDE.
        </p>
        <p>
          I have used maven tool in eclipse Ide to build my spring boot
          application war.
        </p>

        <h6>
          <b>2.Set up a EC2 instance on AWS.</b>
        </h6>
        <p>
          Login to AWS management console, Search and Select service EC2 Don’t
          forget to select your region.
        </p>
        <img src="/images/tomcatsetup.webp" className="img-fluid" alt="" />
        <p>
          Just select Instances and click on Launch Instances (Orange button)
          Now there will be several information required which you can input
          based on your requirement. For OS you can choose Amazon Linux 2 AMI
          (HVM) Generate a keypair, create new security group to allow http(80),
          https and launch the instance. for more detailed information just
          refer document from amazon.
        </p>
        <h6>
          <b>Install JDK and Apache Tomcat</b>
        </h6>
        <p>
          Using the public ip / domain name and generated key pair, connect to
          the instance. Either you can directly connect using aws console or by
          using putty. Just follow the below steps.
        </p>
        <p>
          <b>Update the system</b>
        </p>
        <p>Sudo yum update -y</p>
        <h6>
          <b>Install java 17</b>
        </h6>
        <p>sudo yum install java-17-amazon-corretto</p>
        <h6>
          <b>Navigate to respective folder and download tomcat</b>
        </h6>
        <p>
          wget -c
          https://dlcdn.apache.org/tomcat/tomcat-10/v10.1.7/bin/apache-tomcat-10.1.7.tar.gz
        </p>
        <h6>
          <b>Extract tomcat</b>
        </h6>
        <p>sudo tar xf apache-tomcat-10.1.7.tar.gz</p>
        <h6>
          <b>3. Place the application war inside webapps folder.</b>
        </h6>
        <h6 className="pb-3">
          <b>4. Navigate to bin and start the server using./startup.sh</b>
        </h6>
      </div>
    </>
  );
};

export default TomcatContent;
