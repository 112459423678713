import React from 'react';

const Termsandcondition = () => {
  return (
    <>
      <div className="header-privacy text-center">
        <h1 style={{ color: '#fff' }}>Terms & Conditions</h1>
      </div>
      <div className="container">
        <div className="text-right">
          <p>
            <b style={{ color: '#000', fontSize: '18px' }}>Last Updated: </b>{' '}
            04-11-2024
          </p>
        </div>
        <div className="main-para">
          <p style={{ fontSize: '20px' }}>
            At Amizhth, we value and respect your privacy. This Privacy Policy
            outlines the information we collect, how we use and protect it, and
            your rights regarding your data. By using our website, products, or
            services, you agree to the terms of this Privacy Policy.
          </p>
        </div>
        <div className="lists">
          <h3 className="mb-2">
            <b>1. Acceptance of Terms</b>
          </h3>
          <p>
            By accessing our Site, you agree to comply with these Terms &
            Conditions, our Privacy Policy, and any other policies we may
            provide. We reserve the right to change these Terms & Conditions at
            any time, and any updates will be posted on this page. Your
            continued use of the Site constitutes acceptance of any modified
            terms.
          </p>
        </div>
        <div className="lists">
          <h3 className="mb-2">
            <b>2. Use of Our Site</b>
          </h3>
          <ul>
            <li>
              <p>
                You agree to use the Site only for lawful purposes and in
                compliance with all applicable laws and regulations.
              </p>
            </li>
            <li>
              <p>
                You must not use the Site in a way that disrupts, damages, or
                restricts the use of others.
              </p>
            </li>
            <li>
              <p>
                You agree not to interfere with or compromise the security of
                the Site, or any of the Site’s services, resources, accounts, or
                networks connected to it.
              </p>
            </li>
          </ul>
        </div>
        <div className="lists">
          <h3 className="mb-2">
            <b>3. Intellectual Property</b>
          </h3>
          <p>
            All content on our Site, including text, graphics, logos, images,
            and software, is the property of Amizhth Techno Solutions and
            protected by intellectual property laws. You may not copy,
            reproduce, or distribute any part of the Site’s content without our
            express written consent.
          </p>
        </div>
        <div className="lists">
          <h3 className="mb-2">
            <b>4. Privacy</b>
          </h3>
          <p>
            Our Privacy Policy explains how we collect, use, and protect your
            personal information. By using our Site, you agree to our Privacy
            Policy, which is incorporated by reference into these Terms &
            Conditions.
          </p>
        </div>
        <div className="lists">
          <h3 className="mb-2">
            <b>4. Privacy</b>
          </h3>
          <p>
            Our Privacy Policy explains how we collect, use, and protect your
            personal information. By using our Site, you agree to our Privacy
            Policy, which is incorporated by reference into these Terms &
            Conditions.
          </p>
        </div>
        <div className="lists">
          <h3 className="mb-2">
            <b>5. Third-Party Links</b>
          </h3>
          <p>
            Our Site may contain links to third-party websites for your
            convenience. We are not responsible for the content, privacy
            practices, or accuracy of information on third-party sites, and
            accessing them is at your own risk. These links do not imply our
            endorsement of such sites.
          </p>
        </div>
        <div className="lists">
          <h3 className="mb-2">
            <b>6. Disclaimer of Warranties</b>
          </h3>
          <ul>
            <li>
              <p>
                The Site and its content are provided &quot;as is&quot; without
                warranties of any kind, either express or implied.
              </p>
            </li>
            <li>
              <p>
                We do not warrant that the Site will be error-free,
                uninterrupted, or free from viruses or harmful components.
              </p>
            </li>
            <li>
              <p>
                We disclaim any warranties of merchantability, fitness for a
                particular purpose, or non-infringement.
              </p>
            </li>
          </ul>
        </div>
        <div className="lists">
          <h3 className="mb-2">
            <b>7. Limitation of Liability</b>
          </h3>
          <ul>
            <li>
              <p>
                To the fullest extent permitted by law, Amizhth shall not be
                liable for any direct, indirect, incidental, consequential, or
                punitive damages arising from your use of the Site, even if we
                have been advised of the possibility of such damages.
              </p>
            </li>
            <li>
              <p>
                In jurisdictions where exclusions or limitations of liability
                for damages are not allowed, our liability is limited to the
                fullest extent permitted by law.
              </p>
            </li>
          </ul>
        </div>
        <div className="lists">
          <h3 className="mb-2">
            <b>9. Indemnification</b>
          </h3>
          <p>
            You agree to indemnify, defend, and hold harmless Amizhth, its
            affiliates, employees, and agents from and against any claims,
            liabilities, damages, losses, or expenses, including legal fees,
            arising out of or related to your use of the Site or any violation
            of these Terms & Conditions.
          </p>
        </div>
        <div className="lists">
          <h3 className="mb-2">
            <b>10. Governing Law</b>
          </h3>
          <p>
            These Terms & Conditions shall be governed by and construed in
            accordance with the laws of India, without regard to its conflict of
            law provisions. You agree to submit to the exclusive jurisdiction of
            the courts located in India for any disputes arising out of or
            relating to these Terms & Conditions.
          </p>
        </div>
        <div className="lists">
          <h3 className="mb-2">
            <b>11. Termination</b>
          </h3>
          <p>
            We reserve the right to suspend or terminate your access to the Site
            at our sole discretion, without prior notice, for any conduct that
            we believe violates these Terms & Conditions or is otherwise harmful
            to us or other users.
          </p>
        </div>
        <div className="lists">
          <h3 className="mb-2">
            <b>12. Contact Us</b>
          </h3>
          <p>
            If you have any questions about these Terms & Conditions, please
            contact us at:
          </p>
        </div>
        <p>
          <b style={{ color: '#000', fontSize: '18px' }}>
            Amizhth Techno Solutions
          </b>
        </p>
        <p>
          <b style={{ color: '#000', fontSize: '18px' }}>Email :</b>{' '}
          vanakkam@amizhth.com
        </p>
      </div>
    </>
  );
};

export default Termsandcondition;
