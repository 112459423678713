import React from 'react';

const PrivacyPolicy = () => {
  return (
    <>
      <div className="header-privacy text-center">
        <h1 style={{ color: '#fff' }}>Privacy Policy</h1>
      </div>
      <div className="container">
        <div className="text-right">
          <p>
            <b style={{ color: '#000', fontSize: '18px' }}>Last Updated: </b>{' '}
            04-11-2024
          </p>
        </div>
        <div className="main-para">
          <p style={{ fontSize: '20px' }}>
            At Amizhth, we value and respect your privacy. This Privacy Policy
            outlines the information we collect, how we use and protect it, and
            your rights regarding your data. By using our website, products, or
            services, you agree to the terms of this Privacy Policy.
          </p>
        </div>
        <div className="lists">
          <h3 className="mb-2">
            <b>1. Information We Collect</b>
          </h3>
          <p>We may collect information from you in the following ways:</p>
          <div>
            <h4 className="mb-2">a. Personal Information You Provide</h4>
            <ul>
              <li>
                <p>
                  <b style={{ color: '#000' }}>Contact Details:</b> When you
                  contact us or sign up, we collect information such as your
                  email address, phone number, and company name.
                </p>
              </li>
            </ul>
          </div>
          <div>
            <h4 className="mb-2">b. Automatically Collected Information</h4>
            <ul>
              <li>
                <p>
                  <b style={{ color: '#000' }}>Device Information:</b> We
                  collect information about the device you use to access our
                  website, including IP address, browser type, operating system,
                  and device identifiers.
                </p>
              </li>
              <li>
                <p>
                  <b style={{ color: '#000' }}>Usage Data:</b>We collect
                  information on your interactions with our site, such as pages
                  visited, time spent, and referral sources.
                </p>
              </li>
            </ul>
          </div>
          <div>
            <h4 className="mb-2">c. Cookies and Tracking Technologies</h4>
            <ul>
              <li>
                <p>
                  We use cookies and similar technologies to enhance your
                  experience, analyze trends, and improve our services. You can
                  control cookie preferences through your browser settings.
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className="lists">
          <h3 className="mb-2">
            <b>2. How We Use Your Information</b>
          </h3>
          <p>We use your information for the following purposes:</p>
          <ul>
            <li>
              <p>
                <b style={{ color: '#000' }}>Providing Services:</b> To deliver,
                maintain, and improve our products and services.
              </p>
            </li>
            <li>
              <p>
                <b style={{ color: '#000' }}> Personalization:</b> To
                personalize your experience and recommend relevant services or
                content.
              </p>
            </li>
            <li>
              <p>
                <b style={{ color: '#000' }}> Communication: </b>To respond to
                your inquiries, provide updates, and send administrative
                information.
              </p>
            </li>
            <li>
              <p>
                <b style={{ color: '#000' }}> Marketing: </b>To send you
                promotional materials that may be of interest to you. You can
                opt out of marketing communications at any time.
              </p>
            </li>
            <li>
              <p>
                <b style={{ color: '#000' }}> Analytics: </b>To analyze how our
                site and services are accessed and used, helping us to improve
                our offerings.
              </p>
            </li>
          </ul>
        </div>
        <div className="lists">
          <h3 className="mb-2">
            <b>3. How We Share Your Information</b>
          </h3>
          <p>
            We do not sell, rent, or lease your personal information. We may
            share your information in limited circumstances, including:
          </p>
          <ul>
            <li>
              <p>
                <b style={{ color: '#000' }}>Legal Requirements:</b> We may
                disclose your information if required by law or in response to
                legal processes, such as court orders or subpoenas.
              </p>
            </li>
            <li>
              <p>
                <b style={{ color: '#000' }}>Business Transfers: </b> In the
                event of a merger, acquisition, or asset sale, your data may be
                transferred to the acquiring entity.
              </p>
            </li>
            <li>
              <p>
                <b style={{ color: '#000' }}> Communication: </b>To respond to
                your inquiries, provide updates, and send administrative
                information.
              </p>
            </li>
            <li>
              <p>
                <b style={{ color: '#000' }}> Marketing: </b>To send you
                promotional materials that may be of interest to you. You can
                opt out of marketing communications at any time.
              </p>
            </li>
            <li>
              <p>
                <b style={{ color: '#000' }}> Analytics: </b>To analyze how our
                site and services are accessed and used, helping us to improve
                our offerings.
              </p>
            </li>
          </ul>
        </div>
        <div className="lists">
          <h3 className="mb-2">
            <b>4. Data Security</b>
          </h3>
          <p>
            We take reasonable measures to protect your information from
            unauthorized access, disclosure, alteration, and destruction.
            However, please note that no method of transmission over the
            Internet is 100% secure.
          </p>
        </div>
        <div className="lists">
          <h3 className="mb-2">
            <b>5. Your Rights</b>
          </h3>
          <p>You have rights over your data, which may include:</p>
          <ul>
            <li>
              <p>
                <b style={{ color: '#000' }}>Access</b> You may request to
                access the personal data we hold about you.
              </p>
            </li>
            <li>
              <p>
                <b style={{ color: '#000' }}> Correction</b> You may request
                correction of any inaccuracies in your data
              </p>
            </li>
            <li>
              <p>
                <b style={{ color: '#000' }}> Deletion: </b>You may request
                deletion of your data, subject to legal and contractual
                obligations.
              </p>
            </li>
            <li>
              <p>
                <b style={{ color: '#000' }}>Opt-Out: </b>You may opt out of
                receiving marketing communications from us at any time by
                following the unsubscribe link or contacting us directly.
              </p>
            </li>
          </ul>
        </div>
        <div className="lists">
          <h3 className="mb-2">
            <b>6. Third-Party Links</b>
          </h3>
          <p>
            Our website may contain links to third-party websites. We are not
            responsible for the privacy practices of these sites, and we
            encourage you to review their privacy policies.
          </p>
        </div>
        <div className="lists">
          <h3 className="mb-2">
            <b>7. Children’s Privacy</b>
          </h3>
          <p>
            Our services are not directed to individuals under 13. We do not
            knowingly collect personal information from children under 13. If we
            become aware of such information, we will take steps to delete it
            promptly.
          </p>
        </div>
        <div className="lists">
          <h3 className="mb-2">
            <b>8. Changes to This Privacy Policy</b>
          </h3>
          <p>
            We may update this Privacy Policy from time to time. We will notify
            you of any changes by posting the updated policy on our website with
            a new effective date. We encourage you to review this Privacy Policy
            periodically.
          </p>
        </div>
        <div className="lists">
          <h3 className="mb-2">
            <b>9. Contact Us</b>
          </h3>
          <p>
            If you have questions or concerns about this Privacy Policy or our
            data practices, please contact us at:
          </p>
          <p>
            <b style={{ color: '#000', fontSize: '18px' }}>
              Amizhth Techno Solutions Private Limited
            </b>
          </p>
          <p>
            <b style={{ color: '#000', fontSize: '18px' }}>Email :</b>{' '}
            vanakkam@amizhth.com
          </p>
          <p className="mb-4">
            <b style={{ color: '#000', fontSize: '18px' }}>Address :</b>{' '}
            Madurai, Chennai
          </p>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
