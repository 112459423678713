import React from 'react';
// import { Box } from '@mui/material';
// import { Swiper, SwiperSlide } from 'swiper/react';

const Blog = () => {
  return (
    <>
      <div className="blog_area pt-10 pb-65">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="section_title style-two mb-50 mt-3 wow fadeInDown"
                data-wow-delay=".3"
              >
                <div className="section_sub_title">
                  <p>Our Blog</p>
                </div>
                <div className="section_main_title">
                  <p>Latest from Our Blog</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="single_blog mb-30">
                <div className="single_blog_thumb pb-4">
                  <a href="/blog/cloud-service" aria-label="cloud service">
                    <img
                      src="/images/blog-pic1.webp"
                      alt="Learn about Cloud Services"
                    />
                  </a>
                </div>
                <div className="single_blog_content pl-4 pr-4">
                  <div className="techno_blog_meta">
                    <a href="/blog/cloud-service" aria-label="cloud service">
                      Amizhth
                    </a>
                    <span className="meta-date pl-3">
                      March 24, 2024 | By Pradeepa
                    </span>
                  </div>
                  <div className="blog_page_title pb-59">
                    <p>
                      <a href="/blog/cloud-service" aria-label="cloud service">
                        Modernise Applications by Exploiting the Cloud <br />{' '}
                        Services.
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="single_blog mb-30">
                <div className="single_blog_thumb pb-4">
                  <a
                    href="/blog/springboot-tomcat"
                    aria-label="springboot-tomcat"
                  >
                    <img
                      src="/images/blog-pic2.webp"
                      alt="Spring Boot with Tomcat"
                    />
                  </a>
                </div>
                <div className="single_blog_content pl-4 pr-4">
                  <div className="techno_blog_meta">
                    <a
                      href="/blog/springboot-tomcat"
                      aria-label="springboot-tomcat"
                    >
                      Amizhth
                    </a>
                    <span className="meta-date pl-3">
                      June 3, 2024 | By Antony Basco
                    </span>
                  </div>
                  <div className="blog_page_title pb-59">
                    <p>
                      <a
                        href="/blog/springboot-tomcat"
                        aria-label="springboot-tomcat"
                      >
                        Spring Boot Application on AWS EC2 using Apache Tomcat
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="single_blog mb-30">
                <div className="single_blog_thumb pb-4">
                  <a
                    href="/blog/springboot-docker"
                    aria-label="springboot-dockeer"
                  >
                    <img
                      src="/images/blog-pic3.webp"
                      alt="Spring Boot with Docker"
                    />
                  </a>
                </div>
                <div className="single_blog_content pl-4 pr-4">
                  <div className="techno_blog_meta">
                    <a
                      href="/blog/springboot-docker"
                      aria-label="springboot-dockeer"
                    >
                      Amizhth
                    </a>
                    <span className="meta-date pl-3">
                      August 4, 2024 | By Antony Basco{' '}
                    </span>
                  </div>
                  <div className="blog_page_title pb-35">
                    <p>
                      <a
                        href="/blog/springboot-docker"
                        aria-label="springboot-dockeer"
                      >
                        Spring Boot Application as Docker Container Image on AWS
                        EC2
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Blog;
