import React from 'react';

const Techno = () => {
  return (
    <>
      <div className="counter-area">
        <div className="container">
          <div className="row counter-shap">
            <div className="col-lg-4 col-md-4">
              <div className="counter-title">
                <center>
                  <img
                    src="/images/step.webp"
                    alt="step"
                    className="img-fluid w-25"
                  />
                </center>
                <center>
                  <p>Startup India recognised</p>
                </center>
                <center>
                  <p>Startup</p>
                </center>
              </div>
            </div>
            <div className="col-lg-4 col-md-4">
              <div className="border-line">
                <div className="counter-title">
                  <center>
                    <img
                      src="/images/recg.svg"
                      alt="recg"
                      className="img-fluid"
                      loading="lazy"
                    />
                  </center>
                  <center>
                    <p>Event technology Partner </p>
                  </center>
                  <center>
                    <p>Startup Thiruvizha Madurai</p>
                  </center>
                  <center>
                    <p>Edition</p>
                  </center>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4">
              <div className="counter-title">
                <center>
                  <img
                    src="/images/roc.avif"
                    alt="roc"
                    className="img-fluid w-25"
                    loading="lazy"
                  />
                </center>
                <center>
                  <p>StartupTN empanelled</p>
                </center>
                <center>
                  <p>Startup</p>
                </center>
              </div>
            </div>
            <div className="counter-shape-thumb">
              <img src="/images/box-2.webp" loading="lazy" alt="" />
            </div>
            <div className="counter-shape-thumb1 rotateme">
              <img src="/images/diamond2.png" loading="lazy" alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className="counter-area1">
        <div className="container">
          <div className="row counter-shap">
            <div className="col-lg-4 col-md-4">
              <div className="counter-title">
                <center>
                  <img
                    src="/images/recg.svg"
                    alt="recg"
                    className="img-fluid w-75"
                    loading="lazy"
                  />
                </center>
                <center>
                  <p>Event technology Partner </p>
                </center>
                <center>
                  <p>Startup Thiruvizha Madurai</p>
                </center>
                <center>
                  <p>Edition</p>
                </center>
              </div>
            </div>

            <div className="col-lg-4 col-md-4">
              <div className="counter-title">
                <center>
                  <img
                    src="/images/step.webp"
                    alt="step"
                    className="img-fluid w-25"
                  />
                </center>
                <center>
                  <p>Startup India recognised</p>
                </center>
                <center>
                  <p>Startup</p>
                </center>
              </div>
            </div>

            <div className="col-lg-4 col-md-4">
              <div className="counter-title">
                <center>
                  <img
                    src="/images/roc.avif"
                    alt="roc"
                    className="img-fluid w-25"
                    loading="lazy"
                  />
                </center>
                <center>
                  <p>StartupTN empanelled</p>
                </center>
                <center>
                  <p>Startup</p>
                </center>
              </div>
            </div>
            <div className="counter-shape-thumb">
              <img src="/images/box-2.webp" loading="lazy" alt="" />
            </div>
            <div className="counter-shape-thumb1 rotateme">
              <img src="/images/diamond2.png" alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className="about_area style-five">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div
                className="single_about_thumb wow fadeInDown"
                data-wow-delay=".3"
              >
                <div className="single_about_thumb_inner">
                  {/* <img src="/images/about-main.png" alt="" /> */}
                  <img
                    src="/images/farmer1.webp"
                    alt=""
                    className="farmer-img img-fluid"
                    loading="lazy"
                    sizes=""
                  />
                  <div className="counter-area4"></div>
                </div>
                <div className="em-about-shape-thumb">
                  <div className="em-about-thmub-inner2">
                    <img src="/images/about-sp.webp" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div
                className="section_title style-two mb-30 mt-3 wow fadeInRight"
                data-wow-delay=".4"
              >
                <div className="section_sub_title">
                  <p>About</p>
                </div>
                <div className="section_main_title">
                  <h1>
                    India’s Leading IT Consulting & Software Development Company
                  </h1>
                </div>
                <div className="section_content_text upper">
                  <p>
                    Amizhth is a global IT consulting and software development
                    firm committed to delivering secure and efficient data
                    management and analytics solutions with a focus on web,
                    mobile, cybersecurity, digital marketing, UI/UX, data
                    analytics, IT outsourcing, and cloud-managed services. We
                    help enterprises and software product companies achieve
                    their project goals, overcoming budget and time constraints
                    with tailored, high-quality solutions.
                  </p>
                </div>
              </div>
              <div
                className="em-about-border-box1 pt-1 wow fadeInLeft"
                data-wow-delay=".5"
              >
                <div className="row em-border">
                  <div className="col-md-6 col-lg-6">
                    <div className="em-about-icon-box">
                      <div className="em-about-icon">
                        {/* <img src="/images/icon-rs.png" alt="" /> */}
                        <img
                          src="/images/laptop.webp"
                          style={{ width: '32px' }}
                          alt=""
                          loading="lazy"
                          sizes=""
                        />
                      </div>
                      <div className="em-about-title">
                        <p>
                          <b>Custom Solutions</b>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6">
                    <div
                      className="em-about-icon-box1 wow fadeInDown"
                      data-wow-delay=".6"
                    >
                      <div className="em-about-icon">
                        {/* <img src="/images/icon-cl.png" alt="" /> */}
                        <img
                          src="/images/dependable.webp"
                          style={{ width: '32px' }}
                          loading="lazy"
                          sizes=""
                          alt=""
                        />
                      </div>
                      <div className="em-about-title">
                        <p>
                          <b>Reliable Partnerships</b>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="row wow fadeInRight" data-wow-delay=".7">
                <div className="col-md-6 col-lg-12">
                  <div className="section_button2 mt-40">
                    <div className="abou-button1">
                      <a href="">
                        Contact Us
                        <i className="bi bi-arrow-right" aria-hidden="true"></i
                        ></a>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <div className="em-about-shape">
            <div className="shape-thumb">
              <img src="/images/shape-ab.png" loading="lazy" alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className="counter-area2">
        <div className="container">
          <div className="row counter-shap">
            <div className="col-lg-3 col-md-6">
              <div className="counter-titlee">
                <center>
                  <p className="counter">50+</p>
                </center>
                <center>
                  <p style={{ color: 'white' }}>
                    <span
                      style={{
                        display: 'block',
                        marginTop: '15px',
                        fontSize: '30px'
                      }}
                    >
                      Projects Completed
                    </span>
                  </p>
                </center>
                <div className="ems-border bounce-animate3"></div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="counter-titlee">
                <center>
                  <p className="counter">25+</p>
                </center>
                <center>
                  <p style={{ color: 'white' }}>
                    <span
                      style={{
                        display: 'block',
                        marginTop: '15px',
                        fontSize: '30px'
                      }}
                    >
                      Team size
                    </span>
                  </p>
                </center>
                <div className="em-border1 bounce-animate3"></div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="counter-titlee">
                <center>
                  <p className="counter">6+</p>
                </center>
                <center>
                  <p style={{ color: 'white' }}>
                    <span
                      style={{
                        display: 'block',
                        marginTop: '15px',
                        fontSize: '30px'
                      }}
                    >
                      industries Served
                    </span>
                  </p>
                </center>
                <div className="ems-border bounce-animate3"></div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="counter-titlee">
                <center>
                  <p className="counter">3+</p>
                </center>
                <center>
                  <p style={{ color: 'white' }}>
                    <span
                      style={{
                        display: 'block',
                        marginTop: '15px',
                        fontSize: '30px'
                      }}
                    >
                      Countries Served
                    </span>
                  </p>
                </center>
                <div className="em-border2 bounce-animate3"></div>
              </div>
            </div>
            <div className="counter-shape-thumb">
              <img src="/images/box-2.webp" loading="lazy" alt="" />
            </div>
            <div className="counter-shape-thumb1 rotateme">
              <img src="/images/diamond2.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Techno;
