import React from 'react';
import {
  AboutBanner,
  ChooseUs,
  TeamMember,
  Testimonial
} from '../../sections/AboutPage';

const AboutPage = () => {
  return (
    <>
      <AboutBanner />
      <ChooseUs />
      <TeamMember />
      <Testimonial />
    </>
  );
};

export default AboutPage;
