import React from 'react';
import { DataProvider } from './context/DataContext';
import { ReactNotifications } from 'react-notifications-component';
import './App.css';
import 'react-notifications-component/dist/theme.css';
import Baseroute from './Baseroute';
import { ScrollToTop } from './components';
// import {BrowserRouter as Router,  Route, Routes } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <ReactNotifications />
      <DataProvider>
        <ScrollToTop />
        <Baseroute />
      </DataProvider>
      {/* <Routes>
        <Route path='/under-maintenance' element={<Undermaintenance />} />
        <Route path='/sesstion-timeout' element={<Sesstiontimeout />} />
        <Route path='404' element={<Pagenotfound />} />
      </Routes> */}
    </div>
  );
}

export default App;

// "start": "NODE_ENV=development node scripts/start.js",
//   "start-prod": "NODE_ENV=production node scripts/start.js",
//     "build-dev": "NODE_ENV=development node scripts/build.js",
//       "build-prod": "NODE_ENV=production node scripts/build.js",
