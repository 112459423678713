import React, { createContext } from 'react';
import { useNavigate } from 'react-router-dom';

const DataContext = createContext({});

export const DataProvider = ({ children }) => {
  const navigator = useNavigate();
  return (
    <>
      <DataContext.Provider value={{ navigator }}>
        {children}
      </DataContext.Provider>
    </>
  );
};
export default DataContext;
