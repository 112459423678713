import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
const services = [
  {
    title: 'App Development',
    description:
      'Turning app ideas to fruition - Make your mobile app into reality with us. Amizhth, the best mobile app development company is known for bridging the expertise and experience of top app development experts who help you in all ways to develop functional, high-performance, and immersive mobile apps. Whether you need a customized solution for a particular problem or a new mobile app to set up your business, we are the best choice. Our goal is to provide the best solution for any business. Join us to make your ideas into reality and launch it with excellence.',
    image: '/images/app-dev.webp'
  },
  {
    title: 'Web Development',
    description:
      'Innovative Pioneers - Establish your digital presence with our web development expertise - Amizhth! From enterprise solutions to customized web development, we provide you with web designing solutions to enhance your digital presence. Our skilled developers and designers mix technical prowess with creative ingenuity to provide you with an efficient, profitable website beyond your expectations. Our service helps you to get new lines of business. So dont stand on the sideline - make a difference to your brand by taking your business to the next level.',
    image: '/images/web-dev.webp'
  },
  {
    title: 'IT Outsourcing',
    description:
      'Ideally, IT operations seamlessly support your business and work quietly in the background to enhance your workflow. If Amizhth is your IT outsourcing partner, we will definitely strive for the ideal. As a leading IT outsourcing company, we offer businesses the flexibility to keep moving on with the changing modern world with our advanced IT outsourcing services. We help you with a huge pool of talented resources, enhanced technologies, faster turnaround time for software delivery, and additional focus on core competencies. Hand-shaking with us you can empower your growth by reducing risks, speeding up deliveries, and providing agile solutions.',
    image: '/images/itoutsource.webp'
  },
  {
    title: 'Cyber Security',
    description:
      'Thrive during times of uncertainty and place your security in the hands of Amizhth. We the experts in this domain offer you proactive and reactive solutions to secure your organizations digital infrastructure. Our team of experts leverages industry-leading tools to perform testing to predict the vulnerabilities and uncover potential threats. Moreover, we offer threat monitoring and incident response services to find and mitigate attacks in real-time. Apart from this, our service also ensures that your organization adheres to regulations like HIPAA, GDPR, and PCI-DSS. By hand-shaking with us you can ensure strong, end-to-end cybersecurity to solve the rising threats.',
    image: '/images/cyber.jpg'
  },
  {
    title: 'Digital Marketing',
    description:
      'Navigate the digital landscape with Amizhth - the best digital marketing company that will help to drive traffic, and generate leads. In todays fast-paced digital world having a strong online presence is vital for any kind of business. We excel in transforming your marketing efforts into strong marketing strategies to yield measurable results. Our experts blend creativity with data-driven insights to create personalized campaigns that captivate your audience. Whether you need to drive website traffic, boost brand awareness, or generate leads, our digital marketing service helps your business thrive every day.',
    image: '/images/dig-mar.jpg'
  },
  {
    title: 'UI/UX Design',
    description:
      'UI/UX is the foremost service offered by Amizhth. As a leading UI/UX development company, we excel in developing web and mobile apps. Our UI/UX design services help your businesses to build a scalable and robust user experience in a way to maximizes your ROI and thus helps to meet your business goals.  Our experts strive to combine strategy, technology, and design to create visually stunning digital products that stand ahead of your competitors.',
    image: '/images/uiux.webp'
  },
  {
    title: 'Cloud Managed Services',
    description:
      'With expertise in the cloud domain, Amizhth best cloud service provider offers a tailored cloud migration framework, conducting process assessments, cloud readiness evaluations, and business requirement analyses to craft a future-ready, optimized cloud strategy. With proper planning, strategy, and deployment, we move your workload in a phased manner thus streamlining your infrastructure setup. We support you in enjoying the benefits of DevOps, AiOps, and Agile methodology and also secure the cloud with advanced security solutions. Amizhth will be the right partner for all your cloud-managed services.',
    image: '/images/cloud.jpg'
  },
  {
    title: 'Data Analytics',
    description:
      'We excel in leveraging the power of data to drive insights and solutions for various business sectors. Our dedicated team of engineers and analysts commit themselves to transforming raw data into actionable information, thus providing the strategic edge they expect in todays competitive landscape. Our experts use advanced analytics, machine learning, and predictive modeling to find patterns and forecast future risks and opportunities.  With our analytics solutions, businesses can make smarter decisions, optimize operations, and stay ahead in a data-driven world.',
    image: '/images/data-anal.jpg'
  }
];

const ServiceDigital = () => {
  const [selectedService, setSelectedService] = useState(services[0]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const handleSelectService = (service, index) => {
    setSelectedService(service);
    setSelectedIndex(index);
  };

  $(document).ready(function () {
    $(window).on('scroll', function () {
      if ($(this).scrollTop() > 100) {
        $('#backtotop').fadeIn();
      } else {
        $('#backtotop').fadeOut();
      }
    });
    $('#backtotop').click(function () {
      $('html, body').animate(
        {
          scrollTop: 0
        },
        600
      );
      return false;
    });
  });
  const scrollToTop = () => {
    $('html, body').animate(
      {
        scrollTop: 50
      },
      600
    );
  };

  const [activeService, setActiveService] = useState(null);

  const handleToggleService = (service, index) => {
    setSelectedIndex(index);
    setActiveService(activeService === service ? null : service);
  };
  const handleDownloadProfile = () => {
    console.log('downlaod');

    const pdfUrl = './Amizhth Profile.pdf';
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.download = 'Amizhth_Profile.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <>
      <div className="service-details pages pt-90 pb-50">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-5 col-sm-12">
              <div className="service-details-pn-list">
                <ul>
                  {services.map((service, index) => (
                    <li
                      key={index}
                      className={
                        selectedIndex === index ? 'service-active' : ''
                      }
                    >
                      <a onClick={() => handleSelectService(service, index)}>
                        {service.title}
                        <span>
                          <i className="fa fa-angle-right"></i>
                        </span>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="service-details-big-button mt-40 mb-40">
                <a onClick={() => handleDownloadProfile()}>
                  <span className="details-big-content">
                    Company Profile
                    <i className="fa fa-long-arrow-right"></i>
                  </span>
                </a>
              </div>
              <div
                className="service-details-pn-about mb-4"
                style={{ backgroundImage: 'url(/images/tab1.jpg)' }}
              >
                <div className="service-details-pn-about-content pt-35 pb-40 pl-4 pr-4">
                  <div className="service-details-pn-about-content-title pb-3">
                    <h1>Let us help you grow your business</h1>
                  </div>
                  <div className="service-details-pn-about-content-button pt-2">
                    <Link to="/contact" onClick={scrollToTop}>
                      Contact Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-md-7 col-sm-12">
              <div className="service-main-details">
                <div className="service-main-details-inner">
                  <div className="service-main-details-inner-thumb">
                    <img
                      src={selectedService.image}
                      alt={selectedService.title}
                    />
                  </div>
                  <div className="service-main-details-content-title p pt-4 pb-3">
                    <p sx={{ color: 'black' }}>{selectedService.title}</p>
                  </div>
                  <div className="service-main-details-content-text">
                    <p>{selectedService.description}</p>
                  </div>

                  <div className="service-main-details-content-text pb-2">
                    <p>{selectedService.para1}</p>
                    <p>{selectedService.para2}</p>
                    <p>{selectedService.para3}</p>
                  </div>
                </div>
              </div>
              <div className="service-details-research-button pb-5">
                <a href="/contact" onClick={scrollToTop}>
                  Get This Service
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="accordion-main">
        <div className="service-accordion">
          {services.map((service, index) => (
            <div key={index} className="accordion-item">
              <div
                className={`accordion-header ${selectedIndex === index ? 'service-active' : ''}`}
                onClick={() => handleToggleService(service, index)}
              >
                <h2>{service.title}</h2>
                <span>
                  <i
                    className={`fa ${activeService === service ? 'fa-angle-down' : 'fa-angle-right'}`}
                  ></i>
                </span>
              </div>
              {selectedIndex === index && (
                <div className="accordion-content">
                  <div className="service-main-details-inner-thumb">
                    <img src={service.image} alt={service.title} />
                  </div>
                  <div className="service-main-details-content-title pt-4 pb-3">
                    <p>{service.title}</p>
                  </div>
                  <div className="service-main-details-content-text">
                    <p>{service.description}</p>
                    {service.para1 && <p>{service.para1}</p>}
                    {service.para2 && <p>{service.para2}</p>}
                    {service.para3 && <p>{service.para3}</p>}
                  </div>
                  <div className="service-details-research-button pb-5">
                    <a href="/contact" onClick={scrollToTop}>
                      Get This Service
                    </a>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="service-details-big-button mt-40 mb-40 mx-2">
          <a onClick={() => handleDownloadProfile()}>
            <span className="details-big-content">
              Company Profile
              <i className="fa fa-long-arrow-right"></i>
            </span>
          </a>
        </div>
        <div
          className="service-details-pn-about mb-4 mx-2"
          style={{ backgroundImage: 'url(/images/tab1.jpg)' }}
        >
          <div className="service-details-pn-about-content pt-35 pb-40 pl-4 pr-4">
            <div className="service-details-pn-about-content-title pb-3">
              <h1>Let us help you grow your business</h1>
            </div>
            <div className="service-details-pn-about-content-button pt-2">
              <Link to="/contact" onClick={scrollToTop}>
                Contact Now
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceDigital;
